import {provideKeycloakAuth} from '@12stz/auth-sdk';
import {provideBlocker} from '@12stz/ui-kit/organisms/blocker';
import {provideToast} from '@12stz/ui-kit/organisms/toast';
import {
  provideInterfaceSize,
  provideLocale,
} from '@12stz/ui-kit/quarks/providers';
import {DATE_PIPE_DEFAULT_OPTIONS} from '@angular/common';
import type {Provider} from '@angular/core';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter, RouterOutlet} from '@angular/router';
import {provideStore} from '@ngxs/store';
import {environment} from 'environments';
import {MainComponent} from './main.component';
import {routes} from './main.routing';

const provideDatePipeDefaultOptions = (
  dateFormat = 'dd MMM YYYY HH:mm'
): Provider => ({provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {dateFormat}});

@NgModule({
  bootstrap: [MainComponent],
  declarations: [MainComponent],
  imports: [BrowserModule, RouterOutlet],
  providers: [
    // ng
    provideAnimations(),
    provideRouter(routes),
    provideDatePipeDefaultOptions(),
    provideLocale('ru-RU'),
    // ui-kit
    provideInterfaceSize('small'),
    provideBlocker(),
    provideToast(),
    // auth-sdk
    provideKeycloakAuth({clientId: 'feedback', url: environment.auth}),
    // ngxs
    provideStore([], ...environment.ngxsPlugins),
  ],
})
export class MainModule {}
