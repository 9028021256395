import {roleGuard} from '@12stz/auth-sdk';
import {defaultTo} from '@12stz/ui-kit/quarks/functions';
import {inject} from '@angular/core';
import type {CanActivateFn, GuardResult} from '@angular/router';
import {Router} from '@angular/router';
import type {Observable} from 'rxjs';
import {map, of, switchMap} from 'rxjs';

/** @todo move to auth-sdk */
export const guard =
  (userRoles: string[], redirect?: string[]): CanActivateFn =>
  (route, state): Observable<GuardResult> => {
    const router = inject(Router);
    const parentGuard = roleGuard(...userRoles)(route, state);
    const command = defaultTo([''])(redirect);

    return (parentGuard as Observable<GuardResult>).pipe(
      map((rolesMatch) => rolesMatch as boolean),
      switchMap((pass) => (pass ? of(pass) : router.navigate(command)))
    );
  };
